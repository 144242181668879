// Base class
.primary-section {
  min-height: 20px;
  padding: 19px;
  //background-color: $section-bg;

  //background: #24C6DC; /* fallback for old browsers */
  //background: -webkit-linear-gradient(to left, #24C6DC , #514A9D); /* Chrome 10-25, Safari 5.1-6 */
  //background: linear-gradient(to left, #24C6DC , #514A9D); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background: #D31027; /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #D31027 , #EA384D); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #D31027 , #EA384D); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


  border: 1px solid $section-border;
  color: $gray-lighter;
  @include box-shadow(inset 0 1px 1px rgba(0,0,0,.05));
  blockquote {
    border-color: #dd0001;
    border-color: rgba(0,0,0,.15);
  }
}

// Sizes
.section-lg {
  padding: 24px;
  border-radius: $border-radius-large;
}
.section-sm {
  padding: 9px;
  border-radius: $border-radius-small;
}
