
@mixin button-outline-variant($color) {
  color: $color;
  background-image: none;
  background-color: transparent;
  border-color: $color;

  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: #fff;
    background-color: $color;
        border-color: $color;
  }
  @include hover {
    color: #fff;
    background-color: $color;
        border-color: $color;
  }

  &.disabled,
  &:disabled {
    &:focus,
    &.focus {
      border-color: lighten($color, 20%);
    }
    @include hover {
      border-color: lighten($color, 20%);
    }
  }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}
