$cover-text-color: $gray-lighter;
$cover-text-shadow: 0px 2px 4px rgba(0,0,0,0.5);

.cover-photo-main {
  background: url(../../static/images/deskshot.jpg) no-repeat center center;

  @media (max-width: 770px) {
    background: linear-gradient(
        rgba(0, 0, 0, 0.45),
        rgba(0, 0, 0, 0.45)
    ),url(../../static/images/deskshot.jpg) no-repeat;
    background-size: 220vh;
  }
  background-size: cover;
  min-height: 100vh;
  .btn-lg {
    width: 180px;
    @media only screen and (max-width: 1200px) {
      width: 160px;
    }
    @media only screen and (max-width: 1000px) {
      width: 150px;
      font-size: 17px;
    }
    animation-delay: 4s;
  }

  .col-md-2 {
    position: absolute;
    bottom: 2%;
  }

  .fa {
    color: whitesmoke;
    text-shadow: $cover-text-shadow;
  }
}

.cover-text-main {
  margin-top: 140px;

  h2 {
    @extend .m-t-b-30;
    color: $cover-text-color;
    font-size: 45px;
    font-weight: 400;
    text-shadow: $cover-text-shadow;
  }

  h4 {
    @extend .m-t-b-30;
    color: $cover-text-color;
    font-size: 25px;
    font-weight: 300;
    text-shadow: $cover-text-shadow;
    line-height: 1.3;
    width: 81%;
    @media (max-width: 767px) {
     width: 100%;
    }
  }

  @media (max-width: 768px) {

    @extend .m-t-b-20;
    text-align: center;

    h2 {
      font-size: 30px;
    }
    h4 {
      font-size: 17px;
    }
    .btn {
      @extend .m-t-b-20;
    }
  }
}
