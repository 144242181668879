.image-gallery {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  overflow: scroll;
  padding: 0;
  z-index: 1010;
}
