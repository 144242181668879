@keyframes placeHolderShimmer{
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  //height: 96px;
  //position: relative;
  border: 5px solid #ffffff;
}

.placeholder-background {
  background: #eeeeee;
  border: 5px solid #ffffff;
}

.animated-icon {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
}

.placeholder-background-mask {
  background: #fff;
  position: absolute;
}

.placeholder-img-icon {
  color: #fff;
  position: absolute;
  margin-left: 22%;
  margin-top: -3%;
  height: 70%;
  width: 50%;
}

.mask-left {
  top: 6px;
  left: 6px;
  right: 0;
  width: 8px;
  height: 205px;
}

.mask-top {
  top: 6px;
  left: 6px;
  right: 0;
  width: 95%;
  height: 8px;
}

.mask-right {
  top: 6px;
  left: 94%;
  right: 0;
  width: 8px;
  height: 205px;
}

.mask-bottom {
  top: 203px;
  left: 6px;
  right: 0;
  width: 95%;
  height: 8px;
}

.placeholder-main {
  height: 100%;
}
