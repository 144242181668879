.desc-text {
  margin-bottom: $line-height-computed;
  font-size: floor(($font-size-base * 1.25));
  font-weight: 300;
  line-height: 1.4;
}

.text-width {
  width: 95%;
  margin-left: 2.5%;
}

