.open > .dropdown-menu {
  display: block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}

.dropdown-menu li a {
  font-size: 16px;
  margin: 5px 0 5px 0;
}

.btn-dropdown {
  border: none;
  color: #333;
  background-color: #fff;
  font-size: 18px;
  &:hover {
    color: $brand-primary;
  }
  &:active {
    box-shadow: none;
  }
}

.subscribe-container-div {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1010;
  background-color: rgba(0, 0, 0, 0.5);
}

.subscribe-center-align {
  // height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscribe-popup {
  max-width: 600px;
  padding: 24px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
}

.subscribe-heading {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
}

.subscribe-msg {
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.54);
}
