.card-container {
  display: flex;
  margin: 0.7rem -0.5rem 0 -0.5rem;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: scroll;
  //height: calc(100vh - 58px);
}

.card {
  position: relative;
  display: flex;
  margin-bottom: 0.75rem;
  // padding: 1rem;
  background-color: #fff;
  border-radius: 0.25rem;
  //border: 1px solid rgba(0, 0, 0, .125);
  border: 1px solid rgba(212, 212, 212, 0.43);

  //box-shadow: 0 1px 3px 0 #D4D4D5,0 0 0 1px #D4D4D5;
  height: 200px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  color: $text-color;
}

.auto-height {
  height: auto !important;
}

.card-sm {
  flex-direction: column;
  @include for-size(phone-only) {
    width: 45%;
  }
  @include for-size(tablet-portrait-up) {
    width: 48%;
  }
  @include for-size(tablet-landscape-up) {
    width: 31%;
    margin-left: 1.5%;
  }
  @include for-size(desktop-up) {
    width: 23.5%;
    margin-left: 0.9%;
  }
  @include for-size(big-desktop-up) {
    width: 19%;
    margin-left: 0.6%;
  }
}

.card-patients {
  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25), 0 0px 5px rgba(0, 0, 0, 0.22);
    text-decoration: none;
    color: $text-color;
  }
}

.card-short {
  height: 100px;
}

.card-tall {
  height: 350px;
}

.card > a {
  color: $text-color;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

.card-lg {
  flex-direction: row;
  justify-content: space-between;
}

.item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgba(212, 212, 212, 0.43);
}

.item-title {
}

.card-title-text-light {
  margin: 1rem;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
}

.last-scope {
  border-bottom: 1px solid rgba(212, 212, 212, 0.43);
  padding: 5px;
}

.doctor-avatar-container {
  display: flex;
  flex-direction: row;
  & h3 {
    margin: 0;
    font-size: 24px;
  }
  height: 40px;
}

.doctor-avatar {
  cursor: default;
}

.sb-avatar {
}

.image-item {
  // margin: -1rem -1rem 0 -1rem;
  // overflow: hidden;
  height: 200px;
  width: 100%;
}

.progress-circle-item {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  z-index: 5;
}

.progress-circle-video {
  width: 100%;
  z-index: 5;
}

.recent-scope-download-loading-height {
  height: 169px;
}

.patient-detail-download-loading-height {
  height: 200px;
}

.card-avatar-icon {
  margin-left: 3px;
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes expand {
  0% {
    transform: scale(0.23, 0.45);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce-out {
  20% {
    transform: scale(0.95);
    opacity: 1;
  }
  40% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.bounce-in {
  animation-name: bounce-in;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
}

.bounce-out {
  animation-name: bounce-out;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
}

.fade-out {
  animation-name: fadeOut;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.animate {
  overflow: hidden !important;
  position: relative !important;
}

.animate > div {
  display: none;
}

.animate-div > div > img {
  width: 100% !important;
  height: calc(100vh - 270px) !important;
  position: absolute !important;
  z-index: 100 !important;
  animation-name: expand;
  animation-duration: 0.01s;
  animation-fill-mode: forwards;
}

.animate-div {
  display: block !important;
  width: 100% !important;
  height: calc(100vh - 270px) !important;
  animation-name: expand;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}