// Extending the default .navbar in Bootstrap

.navbar-sms {
  @extend .navbar-default;
  @media (min-width: 767px) {
    background: rgba(0,0,0,0.0);
    .navbar-nav-sms {
      li > a {
        color: #FFFFFF;

        text-shadow: 0 1px 2px rgba(0,0,0,0.5);
        &:hover,
        &:focus {
          color: #d6d6d6;
          text-shadow: 0 1px 2px rgba(0,0,0,.5);
        }
      }
    }
  }
  margin-bottom: 0px;
  padding: 0px 50px 0px 50px;
  @media (max-width: 767px){
    padding: 0px 10px 0px 10px;
  }
  border:none;
  transition: all 0.4s ease-out;
  //transition: visibility 0s, opacity 0.5s linear;
  //font-weight: bold;
}

.navbar-nav {
  li > a {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.navbar-logo {
  background: url("https://s3.amazonaws.com/save-scope/sms-logo-small.png") no-repeat 26px 10px;
  background-size: 157.1px 45px;
  width: 189px;
  height: 59px;
}

.navbar-logo-lg {
  background: url("https://s3.amazonaws.com/save-scope/sms-logo-small.png") no-repeat 26px 10px;
  background-size: 213px 61px;
  width: 242px;
  height: 69px;
  @media (max-width: 767px) {
    background-size: 157.1px 45px;
    width: 189px;
    height: 59px;
  }
}

.navbar-color {
  @extend .navbar-default;
  background-color: white;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  //padding: 0px 50px 0px 50px;
  border: none;
  li > a {
    color: #2c2c2c;
    text-shadow: none;
    &:hover,
    &:active,
    &:focus {
      background-color: #f5f5f5;
    }
  }
  transition: all 0.4s ease-out;
}

.navbar-toggle-sms {
  @extend .navbar-toggle;
  margin-top: 15px;
  border: none;
}


.dash-topbar {
  -webkit-transition: min-width 0.25s ease-out;
  -moz-transition: min-width 0.25s ease-out;
  transition: min-width 0.25s ease-out;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  flex-shrink: 0;
  -ms-flex-negative: 0;
  height: 41px;
  position: relative;
  z-index: 10;
}

.dash-mainbar {
  -webkit-transition: min-width 0.25s ease-out;
  -moz-transition: min-width 0.25s ease-out;
  transition: min-width 0.25s ease-out;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  -ms-flex-direction: row;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  -moz-box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  flex-shrink: 0;
  -ms-flex-negative: 0;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  z-index: 99;
  overflow: visible;
  height: 61px;
}

.dash-mainbar-wrapper {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

//.dash-mainbar div:last-child {
//  margin-left: auto;
//}
