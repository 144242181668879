$button-text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);

.btn-sms {
  @extend .btn-primary;
  background-color: lighten($brand-primary, 10%);
  border-radius: $border-radius-base;
  text-shadow: $button-text-shadow;

  &:hover {
    background-color: $brand-primary;
  }

  &:focus {
    background-color: lighten($brand-primary, 5%);
  }

  &:active {
    background-color: lighten($brand-primary, 5%);
    border-bottom-width: 1px;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.225);
  }
}

// Remove all backgrounds
.btn-primary-outline {
  @include button-outline-variant($btn-primary-bg);
}
.btn-info-outline {
  @include button-outline-variant($btn-info-bg);
}
.btn-success-outline {
  @include button-outline-variant($btn-success-bg);
}
.btn-warning-outline {
  @include button-outline-variant($btn-warning-bg);
}
.btn-danger-outline {
  @include button-outline-variant($btn-danger-bg);
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: normal;
  color: $link-color;
  border-radius: 0;

  &,
  &:active,
  &.active,
  &:disabled {
    background-color: transparent;
    @include box-shadow(none);
  }
  &,
  &:focus,
  &:active {
    border-color: transparent;
  }
  @include hover {
    border-color: transparent;
  }
  @include hover-focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
  }
  &:disabled {
    @include hover-focus {
      color: $btn-link-disabled-color;
      text-decoration: none;
    }
  }
}

// Button Sizes
.btn-md {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size(
    $padding-medium-vertical,
    $padding-medium-horizontal,
    $font-size-medium,
    $line-height-large,
    $btn-border-radius-medium
  );
  margin-left: 10px;
  width: 130px;
}

//
// Block button
//

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type='submit'],
input[type='reset'],
input[type='button'] {
  &.btn-block {
    width: 100%;
  }
}

// Round Buttons
.btn-floating-round {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  overflow: hidden;
  z-index: 1;
  width: 45px;
  height: 45px;
  line-height: 45px;
  padding: 0;
  background-color: $brand-primary;
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;
  vertical-align: middle;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  &:hover {
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -1px rgba(0, 0, 0, 0.2);
  }
  z-index: 5;
}

.btn-download-position {
  top: 170px;
  left: 70%;
}

// Button Icons
.btn-download-icon {
  color: #fff;
  height: 20px;
  width: 20px;
}

// Icon Buttons

.btn-icon {
  border: none;
  background: 0 0;
}


// Light alternate button

.btn-sms-light {
  background-color: #f7f7f7;
  border-radius: 4px;
  /* text-shadow: 0 1px 4px rgba(0,0,0,.4); */
  color: #d31027;
  font-weight: 700;
}
