.sms-dashboard-main {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  min-width: 1px;
  z-index: 0;
  background-color: #F6F6F6;
  height: 100%;
  justify-content: flex-start;
  //height: 100%;
  //display: flex;
  //flex-direction: column;
  //justify-content: flex-start;
  //align-items: stretch;
  //align-content: stretch;
}

.flex-gutter {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 30px;
  -moz-flex: 1 0 30px;
  -ms-flex: 1 0 30px;
  flex: 1 0 30px;
  -webkit-transition: flex-basis 200ms,-webkit-flex-basis 200ms;
  -moz-transition: flex-basis 200ms,-webkit-flex-basis 200ms;
  transition: flex-basis 200ms,-webkit-flex-basis 200ms;
}

.flex-container {
  display: flex;
  box-flex: 1;
  box-orient: vertical;
  flex: 1 1 auto;
  position: relative;
  padding-top: 30px;
  padding-right: 30px;
  // height: calc(100vh - 42px);
}

.flex-container-horizontal {
  display: flex;
  box-flex: 1;
  flex: 1;
  position: relative;
  flex-direction: column;
}

.center-pane-container {
  transition: margin-left 0.25s ease-out,width 0.25s ease-out;
  box-flex: 5500;
  flex: 5500 1 605px;
  min-width: 405px;
  z-index: 2;
}

.center-pane {
  border-radius: 3px 3px 0 0;
  box-shadow: 0 1px 3px rgba(0,0,0,0.3);
  background-color: #fff;
}

.center-content-box-with-widget {
  height: calc(100vh - 160px);
}

.center-content-box-without-widget {
  height: calc(100vh - 90px);
}

.card-main {
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.center-pane-content {

}

.dash-widget {
  display: flex;
  flex-direction: row;
  flex: 1;
  border-bottom: 1px solid #f5f5f5;
}

.dash-widget-patients {
  justify-content: center;
}

.dash-widget-patient-details {
  justify-content: space-around;
}

.dash-widget-element {
  text-align: center;
}
//
//$c: #e4f7ef;
//
//.inner-container-shadow {
//  @include border-radius(.3em);
//  width: 100%;
//  text-align: center;
//  padding: 2em;
//
//  p {
//    margin-bottom: 5em;
//  }
//}
//
//.outer-container-shadow {
//  @include border-radius(.3em);
//  border: 1px solid darken($c, 8%);
//  width: 20em;
//  height: 20em;
//  margin: 5em auto 0;
//  overflow-y: scroll;
//  @include background-image(
//      linear-gradient(top, #fff, rgba(255, 255, 255, 0)),
//      linear-gradient(bottom, #fff, rgba(255, 255, 255, 0)),
//      linear-gradient(top, darken($c, 20%), rgba(255, 255, 255, 0)),
//      linear-gradient(bottom, darken($c, 20%), rgba(255, 255, 255, 0))
//  );
//  background-position: 0 0, 0 100%, 0 0, 0 100%;
//  background-repeat: no-repeat;
//  background-color: white;
//  background-size: 100% 5em, 100% 5em, 100% 1em, 100% 1em;
//  background-attachment: local, local, scroll, scroll;
//}
