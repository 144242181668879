.video-player-div > div > div > button {
  display: none !important;
}

.video-player-div > div > video {
  outline: none !important;
}

.video-player-div > div {
  outline: none !important;
}

.video-player-div {
  position: relative;
  padding: 0;
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1005;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
  right: 0;
}

.player-close-btn {
  height: 20px;
  width: 20px;
  color: #777777;
}

.player-close-btn-position {
  position: absolute;
  left: 10px;
  top: 15px;
  z-index: 10;
  cursor: pointer;
}

.video-buttons {
  position: absolute;
  top: 0;
  height: 410px;
  z-index: 5;
  display: flex;
  align-items: center;
}

.video-buttons > a {
  color: #555 !important;
  cursor: pointer;
}

.video-patient-info {
  display: flex;
  flex-direction: row;
  align-items: row;
  justify-content: space-between;
}

.video-cared-by {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 250;
  flex-wrap: wrap;
}

#progress {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

#bar {
  width: 0;
  height: 2px;
  background-color: #ce142a;
  display: none;
  transition: width 0.5s;
  box-shadow: 0 0 5px 1px black;
}

.select-container {
  display: flex;
  align-items: center;
}

.select-container > div:first-child {
  flex: 1;
}

.create-tag,
.create-tag:hover,
.create-tag:focus {
  font-size: 20px;
  margin-left: 5px;
  color: #555;
  cursor: pointer;
}

.asset-tag {
  margin: 5px;
  background-color: #dcdcdc;
  border-radius: 30px;
  padding: 5px;
  display: flex;
  align-items: center;
}

.asset-tag-btn,
.asset-tag-btn:hover,
.asset-tag-btn:focus {
  margin-left: 5px;
  font-size: 14px;
  line-height: 10px;
  cursor: pointer;
  color: #888;
}

.asset-tags-div {
  display: flex;
  margin-top: 50;
  flex-wrap: wrap;
}

.image-div {
  width: 100%;
  background-color: #000;
  text-align: center;
}

.image {
  width: 70%;
  height: 100%;
}

.user-comment-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  width: 100%;
  justify-content: flex-end;
}

.user-comment {
  padding: 10px;
  border-radius: 15px;
  border-top-right-radius: 2px;
  background-color: #3d7ce4;
  margin: 0;
  color: #fff;
}

.comment-div {
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.comment {
  padding: 10px;
  border-radius: 15px;
  border-top-left-radius: 2px;
  background-color: #dcdcdc;
  margin: 0;
  width: fit-content;
}

.comments-scroll {
  max-height: 300px;
  min-height: 300px;
  overflow-y: scroll;
}

.reverse-items {
  display: flex;
  flex-direction: column-reverse;
}

.comment-date {
  font-size: 10px;
  display: inline-block;
  width: 100%;
  text-align: right;
  padding-right: 10px;
}

.comment-remove-btn {
  margin: 0;
  margin-right: 5px;
  font-size: 16px;
  line-height: 10px;
  color: #888;
  cursor: pointer;
}

.comment-remove-btn:hover {
  color: #888;
  text-decoration: none;
}

.comment-input-span {
  display: flex;
  align-items: center;
  width: 100%;
  border-style: solid;
  border-color: #dcdcdc;
  border-width: 0;
  border-top-width: 2px;
}

.comment-input {
  padding: 5px;
  border: none;
  outline: none;
  flex-grow: 1;
}

.create-comment-btn {
  margin-left: 10px;
  font-size: 16px;
  color: grey;
  cursor: pointer;
}

.user-comment-span {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
