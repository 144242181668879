$app-adapter-img-height: 361px;

.animate-hidden {
  visibility: hidden;
}

.fa-chevron-down {
  cursor: pointer;
}

.home-app-img {
  background: url("../../static/images/sms-app-home.png") no-repeat;
  height: $app-adapter-img-height;
  width: 282px;
  top: -20px;
}

.home-adapter-img {
  background: url("../../static/images/sms-adapter-home.png") no-repeat;
  height: $app-adapter-img-height;
}

.app-adapter-brief {

  h3 {
    @extend .m-t-b-20;
  }

  h4 {
    font-style: italic;
    @extend .m-t-b-20;
  }

  p {
    @extend .m-t-b-20;
    @extend .desc-text; // This is from components/_type.scss defining how
  }
  @media only screen and (max-width: 767px) {
    text-align: center;
  }
}

.font-icon-large {
  font-size: 70px!important;
}

