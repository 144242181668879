.product-box {
  display: -webkit-box;
  @media (max-width: 767px) {
    -webkit-box-orient: vertical;
  }
}

.pane-absolute {
  position: absolute;
}

.pane-fixed {
  position: fixed;
}

.product-pane {
  height: 100vh;
  top: 0;
  padding: 0;
  .col-sm-6 {
    top: 45%;
  }
  transition: width 0.4s linear;
}

.product-order-a {
  -webkit-box-ordinal-group: 1;
}

.product-order-b {
  -webkit-box-ordinal-group: 2;
}

.adapter-pane {
  background: url(../images/adapter-bg.png) no-repeat;
  background-color: lighten($brand-primary, 30%);
  background-size: 500px auto;
  @media (min-width: 1200px ) {
    background-size: 90%;
  }
  @media (min-width: 768px) {
    background-size: 90%;

  }
  //padding: 0;
  color: $gray-lighter;
  height: 100vh;
}

.adapter-desc {
  //visibility: hidden;
}

.pane-fixed {
  position: fixed;
}

.desc-hidden {
  display: none;
}

.panel-fixed {
  position: fixed;
}

.app-pane {
  background: url(../images/app-bg.png) no-repeat;
  background-color: lighten($brand-info, 20%);
  @media (min-width: 1200px ) {
    background-size: 90%;
  }
  @media (min-width: 768px) {
    background-size: 80%;
  }
  height: 100vh;
}
