* {
  margin: 0;
}

html, body {
  height: 100%;
}

.wrapper-footer {
  min-height: calc(100vh - 121px)
}

.wrapper-footer:after {
  display: block;
  content: "";
}

.footer-main, .wrapper-footer:after {
  height: 100%;
}

.footer-main {
  position: relative;
  background: #F6F6F6;
  border-top: 1px solid #C7C7C7;
  //margin-top: 20px;
  ul > li {
    list-style: none;
  }
  .col-md-3, .col-xs-10, .col-sm-4 {
    float: none;
    margin: 0 auto;
    padding: 12px 0px 12px 0px;
  }
}

.aptible-logo {
  background: url(../../static/images/aptible-logo.png) no-repeat;
  background-size: 150px 39px;
  height: 39px;
  width:150px;
  display:block;
  margin-top: 10px!important;
}

.footer-text {
  font-size: 12px;
  a {
    color: darkgrey;
    padding: 15px;
    &:hover {
      text-decoration: none;
    }
  }
}
