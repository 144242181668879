// Position Class

.pos-r {
  position: relative;
}

//Margin Helper Classes, have created them for 10, 20, 30 and 50 pixels

.m-t-0 {
  margin-top: 0;
}

.m-b-0 {
  margin-bottom: 0;
}

.m-t-10 {
  margin-top: 10px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-b-10 {
  @extend .m-t-10;
  @extend .m-b-10;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-b-20 {
  @extend .m-t-20;
  @extend .m-b-20;
}

.m-t-30 {
  margin-top: 30px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-t-b-30 {
  @extend .m-t-30;
  @extend .m-b-30;
}

.m-t-50 {
  margin-top: 50px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.m-t-b-50 {
  @extend .m-t-50;
  @extend .m-b-50;
}

.m-t-70 {
  margin-top: 70px;
}

.m-b-70 {
  margin-bottom: 70px;
}

.m-t-b-70 {
  @extend .m-t-70;
  @extend .m-b-70;
}

// Padding Helper Classes, have created them for 10, 20, 30 and 50 pixels

.p-t-10 {
  padding-top: 10px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-t-b-10 {
  @extend .p-t-10;
  @extend .p-b-10;
}

.p-t-20 {
  padding-top: 20px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-t-b-20 {
  @extend .p-t-20;
  @extend .p-b-20;
}

.p-t-30 {
  padding-top: 30px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.p-t-b-30 {
  @extend .p-t-30;
  @extend .p-b-30;
}

.p-t-50 {
  padding-top: 50px;
}

.p-b-50 {
  padding-bottom: 50px;
}

.p-t-b-50 {
  @extend .p-t-50;
  @extend .p-b-50;
}

// Auto Center Column Help Class

.col-centered {
  float: none;
  margin: 0 auto;
}

.divider-line {
  display: block;
  width: 85%;
  height: 1px;
  background-color: #e5e5e5;
  margin-left: 7.5%;
}

.error-div {
  position: absolute;
  top: 0;
  right: 0;
  margin: 100px 200px 0px 200px;
  width: 50%;
  box-shadow: 0px 5px 10px -6px rgba(0, 0, 0, 0.5);
  z-index: 1010;
}
